.lg {
    width: 3rem;
    height: 2rem;
}

.md {
    width: 2.75rem;
    height: 1.5rem;
}

.sm {
    width: 2.25rem;
    height: 1.25rem;
}
